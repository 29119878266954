<template>
  <div>
    <wrapper-with-back :link="backLink" class="show-replacement">
      <ShowJob
        :loader="showBuyoutAmountLoader"
        :job-info="jobInfo"
        :status-heading="buyoutSection ? 'Please review offered buyout' : statusHeading"
        :show-cancel="showCancelBtn"
        :cancellation-popup-data="cancellationPopupData.job"
        @rerequest="$emit('rerequest')"
      >
        <div v-if="showOptionsSection" class="show-replacement__replacement-option">
          <div class="show-replacement__replacement-option-device">
            <div class="show-replacement__replacement-option-device-container">
              <DeviceIcon
                class="show-replacement__replacement-option-device-icon"
                :icon="productLineName"
                mini
              />
              <div class="show-replacement__replacement-option-device-info">
                <p class="show-replacement__replacement-option-device-name">{{ optionName }}</p>
                <p class="show-replacement__replacement-option-device-attributes">
                  {{ optionAttributesText }}
                </p>
              </div>
            </div>
          </div>
          <!-- <p class="show-replacement__replacement-option-device-attributes--mobile">
            {{ optionAttributesText }}
          </p> -->
          <p class="show-replacement__text" v-html="statusHtml"></p>

          <StatusButtons
            :disabled="cancelForBuyoutInprogress"
            :decline-disabled="cancelForBuyoutInprogress"
            v-if="!acceptionInProgress"
            class="show-replacement__status-buttons"
            confirm-button-text="Accept & Proceed to Payment"
            decline-button-text="I Would Like a Buyout"
            @confirm="goToPayment"
            @decline="chooseBuyout"
          />
        </div>
        <BuyoutStatus
          v-else-if="buyoutSection"
          declineButtonText="Return to Replacement"
          confirmButtonText="Proceed with Buyout"
          @confirm="cancelServifyClaimForBuyout"
          @decline="buyoutSection = false"
          @confirmed="onBuyoutDone"
          manual-decline
        >
          <span v-html="buyoutText"></span>
        </BuyoutStatus>

        <BuyoutStatus
          v-else-if="
            hasStatus(
              'replacementUnavailable',
              'deviceNotRepairable',
              'amountAvailable',
              'replacementDeclined',
              'replacementExpired'
            )
          "
          :cancellation-popup-data="cancellationPopupData.buyout"
          @confirm="cancelServifyClaimForBuyout"
          @decline="cancelServifyClaimForBuyout"
          @confirmed="onBuyoutDone"
          @declined="onBuyoutDone"
        >
          <span v-html="statusHtml"></span>
        </BuyoutStatus>

        <div v-else class="show-replacement__text" v-html="statusHtml"></div>

        <PaymentLoader v-if="paymentLoader" />
      </ShowJob>
    </wrapper-with-back>
  </div>
</template>
<script>
import {mapActions, mapState, mapMutations} from 'vuex';
import ShowJob from './ShowJob';
import jobStatusMappingMixin from '@/mixins/jobStatusMappingMixin';
import {statuses, statusCodesResolver} from '@/constants/replacement/statuses';
import buyoutStatuses from '@/constants/buyout/statuses';
import BuyoutStatus from '@/components/job/BuyoutStatus';
import StatusButtons from '@/components/job/StatusButtons';
import cancellationPopupData from '@/constants/replacement/cancellation_popup_data';
import DeviceIcon from '@/components/shared/DeviceIcon';
import PaymentLoader from '@/components/claim/ClaimPaymentLoaderModal';
import {scBase} from '@/constants/web-redirects';

export default {
  components: {
    ShowJob,
    BuyoutStatus,
    DeviceIcon,
    StatusButtons,
    PaymentLoader,
  },
  data() {
    return {
      buyoutSection: false,
      acceptionInProgress: false,
      cancelForBuyoutInprogress: false,
    };
  },
  mixins: [jobStatusMappingMixin],
  computed: {
    ...mapState('requestService', ['paymentLoader']),
    ...mapState('deviceInformation', ['productLine', 'productLineName']),
    ...mapState('user', ['contractDetails']),
    backLink() {
      const {customerRef, contractRef} = this.$route.query;
      const query = {customerRef, contractRef};
      return {
        name: 'MyDevices',
        query,
      };
    },
    showCancelBtn() {
      return (
        this.hasStatus(
          'replacementAuthorized',
          'replacementOptionProvided',
          'New',
          'NEW',
          'INITIATED',
          'PAYMENT SUCCESSFUL'
        ) && !this.cancelledClaim
      );
    },
    showOptionsSection() {
      return (
        this.hasStatus('replacementOptionProvided') &&
        !this.cancelledClaim &&
        this.isAnyReplacementOption &&
        !this.buyoutSection
      );
    },
    showBuyoutSection() {
      return this.hasStatus('replacementUnavailable') || this.buyoutSection;
    },
    isAnyReplacementOption() {
      return this.jobInfo?.claimDetails?.replacementDetails?.length;
    },
    replacementOption() {
      return this.jobInfo?.claimDetails?.replacementDetails
        ? this.jobInfo?.claimDetails?.replacementDetails[0]
        : null;
    },
    cancelledClaim() {
      return (
        this.jobInfo?.serviceJobStatus === 'CAN' &&
        this.jobInfo?.serviceJobStatusName === 'Cancelled'
      );
    },
    masterID() {
      return this.jobInfo?.claimsMasterId;
    },
    code() {
      return this.jobInfo?.serviceJobSubstatus;
    },
    statuses: () => statuses,
    buyoutStatuses: () => buyoutStatuses,
    buyoutAmount() {
      return parseFloat(this.jobInfo?.claimDetails?.buyoutAmount);
    },
    showBuyoutAmountLoader() {
      if (!this.jobInfo?.serviceJobID?.toLowerCase().startsWith('hscm')) {
        return false;
      }
      return !this.buyoutAmount;
    },
    buyoutText() {
      return buyoutStatuses.replacementDeclining.text(this.jobInfo);
    },
    statusCodesResolver: () => statusCodesResolver,
    cancellationPopupData: () => cancellationPopupData,
    optionName() {
      return `${this.replacementOption?.brandName} ${this.replacementOption?.modelName}`;
    },
    optionAttributes() {
      return this.replacementOption?.specificationDetails ?? [];
    },
    optionAttributesText() {
      return this.optionAttributes?.map((i) => `${i.specName}: ${i.specValue}`).join(' | ');
    },
    jobId() {
      return this.$route.params.id;
    },
  },
  watch: {
    productLine: {
      handler() {
        if (this.productLine && this.replacementOption && this.$route.query.accept) {
          this.acceptReplacement();
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('requestService', ['SET_PAYMENT_PROGRESS']),
    ...mapActions('requestService', ['ACCEPT_REPLACEMENT_OPTION', 'CANCEL_SERVIFY_CLAIM']),
    getRedirectUrl() {
      const {query} = this.$route;
      delete query.accept;

      const {href} = this.$router.resolve({
        name: 'ShowJob',
        params: {
          id: this.jobId,
        },
        query: {
          ...query,
          accept: 'acceptOption',
        },
      });

      return `${window.location.origin}${href}`;
    },
    async goToPayment() {
      this.SET_PAYMENT_PROGRESS(true);
      const {customerRef} = this.$route.query;
      const decryptedRequestParams = await this.$store.dispatch('user/DECRYPT_PARAMS', {
        customerRef,
      });
      const encryptedRequestParams = await this.$store.dispatch('user/ENCRYPT_PARAMS', {
        customerRef: decryptedRequestParams?.customerRef,
        redirectUrl: this.getRedirectUrl(),
        claimsMasterId: this.masterID,
      });

      const paymentUrl = `${scBase}/sc/payment/ceclaimspayment?customerref=${encryptedRequestParams.customerRef}&claimsmasterid=${encryptedRequestParams.claimsMasterId}&redirectUrl=${encryptedRequestParams.redirectUrl}`;
      window.location = paymentUrl;
    },
    async chooseBuyout() {
      // cancel replacement for buyout
      // once servify claim is cancelled
      // user is redirected to device list
      // TODO: Refactor this to popup confirmation.
      if (this.code === 'SC0102') {
        this.cancelForBuyoutInprogress = true;
        const {customerRef, contractRef} = this.$route.query;
        const contract = this.contractDetails || {};
        const PartnerCode = contract?.partnerCode;

        // cancel servify claim
        await this.CANCEL_SERVIFY_CLAIM({
          customerRef,
          contractRef,

          claim: this.jobInfo,
          reason: 'BUYOUT_CANCEL',

          PartnerCode,
        });

        return;
      }
      this.buyoutSection = true;
    },
    onBuyoutDone() {
      this.buyoutSection = false;
      this.$emit('rerequest');
    },
    async acceptReplacement() {
      this.acceptionInProgress = true;
      const {customerRef, contractRef} = this.$route.query;
      await this.ACCEPT_REPLACEMENT_OPTION({
        customerRef,
        contractRef,
        replacementOption: {
          productSubCategoryCode: this.productLine,
          productCode: this.replacementOption.model,
          brandCode: this.replacementOption.brand,
        },
        claim: this.jobInfo,
      });
      const routeQuery = this.$route.query;
      delete routeQuery.accept;
      this.$router.replace({
        name: this.$route.name,
        params: {...this.$route.params},
        query: {...routeQuery},
      });
      this.$emit('rerequest');
    },
  },
};
</script>
<style lang="scss" scoped>
.show-replacement {
  padding: 20px 0;
  color: $darkGray;
  font-size: 18px;

  @include mf($gridMDBreakpoint) {
    padding: 60px 0;
  }

  &__replacement-option-device {
    margin: 15px 0 0 0;

    @include mf($gridMDBreakpoint) {
      margin: 15px 0;
    }
  }
  &__status-buttons {
    margin-top: 0;
  }

  &__replacement-option-device-icon {
    width: 45px;
    height: 45px;
  }
  &__replacement-option-device-container {
    display: flex;
    gap: $gridMDGutter;
    align-items: center;
    font-size: 20px;
  }
  &__replacement-option-device-name {
    font-weight: 700;
  }
  &__replacement-option-device-attributes {
    display: none;

    @include mf($gridMDBreakpoint) {
      display: inherit;
    }

    &--mobile {
      display: inherit;
      margin: 8px 0 5px;

      @include mf($gridMDBreakpoint) {
        display: none;
      }
    }
  }
}
</style>